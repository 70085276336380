import React from 'react';
import {Link} from 'gatsby';

import Divider from '../components/Divider';
import CreativeCommonsInfo from '../components/CreativeCommonsInfo';
import dictionary from '../data/_dictionary';

const ArticleContent = ({article, lang, slug, alone}) => {
    const {audio, authors, doi, keywords, item_abstracts, pdf, title, title_en, date, received, accepted} = article.frontmatter;
    const keywordsLast = keywords[lang].length - 1;
    const item_abstract = item_abstracts[lang] || item_abstracts['en'] || '';

    return (
        <div>
            {!alone && <Divider weak />}
            <h4>{title}{title_en ? ` / ${title_en}` : ''}</h4>
            {authors.map(({firstName, lastName, affiliation, orcid}, i) => (
                <div key={`${slug}-authors-${i}`}>
                    <Link to={`${lang}/search-by-author?query=${firstName} ${lastName}`}>{firstName} {lastName}</Link>
                    , {affiliation[lang]}{orcid && ` (ORCID: ${orcid})`}
                </div>
            ))}
            <p>
                <strong>DOI: </strong>
                {doi ? <a href={`https://doi.org/${doi}`}>{doi}</a> : '---'}
                {` (${dictionary.publishedOnline[lang]}: ${date.slice(0, 10)})`}
                {!received ? null :
                    (<span>
                        <br />
                        <strong>{dictionary.received[lang]}:</strong> {received.slice(0, 10)}
                    </span>)
                }
                {!accepted ? null :
                    (<span>
                        <br />
                        <strong>{dictionary.accepted[lang]}:</strong> {accepted.slice(0, 10)}
                    </span>)
                }
            </p>
            <p><strong>{`${dictionary.keywords[lang]}: `}</strong>
                {keywords[lang].map((keyword, i) => (
                    <span key={`${slug}-keywords-${i}`}>
                        <Link to={`${lang}/search-by-keyword?query=${keyword}`}>{keyword}</Link>
                        {i < keywordsLast && ', '}
                    </span>
                ))}
            </p>
            <Divider weak />
            <div dangerouslySetInnerHTML={{__html: `<p>${item_abstract}</p>`}} />
            <p>→ <a href={pdf}>PDF</a></p>
            {audio && audio.map(({file, label}, i) => (
                <div className="audio-container" key={`${slug}-audio-${i}`}>
                    <audio controls src={file}>
                        Your browser does not fully support the <code>audio</code> element.
                    </audio>
                    <p>{label[lang] ? label[lang] : `${dictionary.audioFile[lang]} ${i + 1}`}</p>
                </div>
            ))}
            {alone && <CreativeCommonsInfo lang={lang} dividerTop />}
        </div>
    );
};

export default ArticleContent;
